import * as React from 'react';
import './AboutTextLeft.css';


const AboutText: React.FC = () => {
    return (
        <div className='about-section'>
            <h1 className="about-title">About me</h1>
            <p className="about-text">Hi there!</p>
            <p className="about-text">
                I'm Stefan Lacher, a passionate Data Engineer, System Architect and Sociologist
                with a knack for transforming raw data into valuable insights.
                With a diverse skill set spanning multiple technologies,
                I excel at navigating complex data landscapes to deliver comprehensive,
                end-to-end solutions. I thoroughly enjoy tackling problems from planning
                and experimentation to professionalization and bringing solutions to
                production level.
            </p>
            <p className="about-text">
                With a Master’s in Sociology from the University of Graz,
                I have a strong foundation in both the theoretical and practical
                aspects of data science and engineering. My academic journey and
                professional experiences have equipped me with the ability to
                address real-world problems through meticulous research and
                advanced data methodologies.
            </p>
        </div>
    );
};

export default AboutText;