import * as React from 'react';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import ImpressumText from '../components/footer/impressum/ImpressumText';


const Impressum: React.FC = () => {
    return (
        <div>
            <Header />
            <ImpressumText />
            <Footer />
        </div>
    );
};

export default Impressum;