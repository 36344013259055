import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import './App.css';
import Footer from './components/footer/Footer';
import Homepage from './pages/Home';
import Impressum from './pages/Impressum';
import PrivacyPolicy from './pages/PrivacyPolicy';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const HandleRedirect = () => {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const redirectPath = searchParams.get('redirect_path');

    useEffect(() => {
        if (redirectPath) {
            navigate(redirectPath);
        }
    }, [redirectPath, navigate]);

    return null;
};

function App() {
    return (
        <div className="App">
            <Router>
                <ScrollToTop />
                <HandleRedirect />
                <Footer />
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
