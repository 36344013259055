import * as React from 'react';
import { FaLinkedin, FaMedium, FaGithub } from 'react-icons/fa';
import { MdOutlineEmail } from "react-icons/md";

import './AboutTextDown.css';


const AboutTextD: React.FC = () => {
    return (
        <div className='about-section'>
            <h1 className="about-title">Contact me</h1>
            <p className="about-text">
                Feel free to reach out if you have interesting opportunities or
                just want to discuss the fascinating world of data!
                Connect with me on LinkedIn or check out my articles on Medium or projects on GitHub.
            </p>
            <div className="social-icons">
                <a href="https://www.linkedin.com/in/stefan-lacher/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin className="icon" />
                </a>
                <a href="https://stefan-lacher.medium.com/" target="_blank" rel="noopener noreferrer">
                    <FaMedium className="icon" />
                </a>
                <a href="https://github.com/Stefan-Lacher" target="_blank" rel="noopener noreferrer">
                    <FaGithub className="icon" />
                </a>
                <a href="mailto:contact@stefanlacher.com" target="_blank" rel="noopener noreferrer">
                    <MdOutlineEmail className="icon" />
                </a>
            </div>
        </div>
    );
};

export default AboutTextD;