import * as React from 'react';
import './PrivacyPolicyText.css';

const PrivacyText: React.FC = () => {
    return (
        <div className='privacy'>
            <h1 className='privacy-title'>Datenschutzerklärung</h1>
            <div className='privacy-text'>
                <h2 className='privacy-title-2'>Einleitung und Überblick</h2>
                <p>
                    Wir haben diese Datenschutzerklärung (Fassung 30.07.2024-112847357) verfasst, um Ihnen gemäß 
                    der Vorgaben der 
                    <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=112847357#d1e2269-1-1" target="_blank" rel="noopener">
                        Datenschutz-Grundverordnung (EU) 2016/679
                    </a> 
                    und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und 
                    die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen 
                    Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.
                    <br />
                    <strong>Kurz gesagt: </strong>
                    Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.
                </p>

                <h2 className='privacy-title-2'>Kontaktdaten des Verantwortlichen</h2>
                <p>
                    <strong>Verantwortlicher</strong> <br />
                    Stefan Lacher <br />
                    Theodor-Sickel-Gasse 12, 1100 Wien, Österreich <br />
                    Email: <a href="mailto:contact@stefanlacher.com">contact@stefanlacher.com</a>
                </p>

                <h2 className='privacy-title-2'>Keine Datenspeicherung und Verarbeitung</h2>
                <p>Wir sammeln, speichern und verarbeiten keine personenbezogenen Daten von Besuchern unserer Website.</p>

                <h2 className='privacy-title-2'>Social Media</h2>
                <p>
                    Auf unserer Website finden Sie Links zu unseren Social-Media-Profilen (LinkedIn, Medium, Github). 
                    Diese Plattformen können personenbezogene Daten sammeln, wenn Sie deren Links anklicken und weitergeleitet werden. 
                    Bitte beachten Sie die Datenschutzerklärungen der jeweiligen Plattformen:

                    <li>
                        <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener">LinkedIn</a>
                    </li>
                    <li>
                        <a href="https://policy.medium.com/medium-privacy-policy-f03bf92035c9" target="_blank" rel="noopener">Medium</a>
                    </li>
                    <li>
                        <a href="https://docs.github.com/en/site-policy/privacy-policies/github-general-privacy-statement" target="_blank" rel="noopener">GitHub</a>
                    </li>
                </p>

                <h2 className='privacy-title-2'>Ihre Rechte laut DSGVO</h2>
                <p>
                    Auch wenn wir keine personenbezogenen Daten verarbeiten, möchten wir Sie auf Ihre Rechte hinweisen:

                    <li>Recht auf Auskunft (Art. 15 DSGVO)</li>
                    <li>Recht auf Berichtigung (Art. 16 DSGVO)</li>
                    <li>Recht auf Löschung (Art. 17 DSGVO)</li>
                    <li>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
                    <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
                    <li>Widerspruchsrecht (Art. 21 DSGVO)</li>
                </p>

                <h2 className='privacy-title-2'>Schlusswort</h2>
                <p>
                    Wenn Sie Fragen zu unserer Datenschutzerklärung haben, wenden Sie sich bitte an uns 
                    über die im Impressum genannten Kontaktdaten. 
                    Wir wünschen Ihnen noch eine schöne Zeit auf unserer Website.
                </p>
            </div>
        </div>
    );
};

export default PrivacyText;
