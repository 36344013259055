import * as React from 'react';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import PrivacyText from '../components/footer/PrivacyPolicy/PrivacyPolicyText';


const PrivacyPolicy: React.FC = () => {
    return (
        <div>
            <Header />
            <PrivacyText />
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;