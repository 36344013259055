import * as React from 'react';
import './Footer.css';
import heartFooterLogo from './love.png';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-top">
                <a href="/impressum">Impressum</a>
                <a href="/privacy">Privacy Policy</a>
                <a href="/#contact_me">Contact</a>
            </div>
            <div className='footer-bottom'>
                <span className="footer-text footer-text-right">© 2024</span>
                {/* <span className="footer-separator">|</span> */}
                <span className="footer-text footer-text-right">Made with</span>
                <img src={heartFooterLogo} alt="heart logo" className="heart-footer-logo" />
                {/* <span className="footer-separator">|</span> */}
                <span className="footer-text footer-text-left">Stefan Lacher</span>
            </div>
        </footer>
    );
};

export default Footer;