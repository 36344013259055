import * as React from 'react';
import './ImpressumText.css';

const ImpressumText: React.FC = () => {
    return (
        <div className='impressum'>
            <h4 className='impressum-title'>Impressum</h4>
            <div className='impressum-text'>
                <p><b>Informationen und Offenlegung gemäß &sect;5 (1) ECG, &sect; 25 MedienG, &sect; 63 GewO und &sect; 14 UGB</b></p>
                <p><b>Webseitenbetreiber:</b> Stefan Lacher</p>
                <p><b>Anschrift:</b> Theodor-Sickel-Gasse 12, 1100 Wien</p>
                <p><b>Kontaktdaten:</b>
                <br />
                Email: contact@stefanlacher.com
                </p>

                <p><b>Anwendbare Rechtsvorschrift:</b> www.ris.bka.gv.at
                <br />
                </p>
                <p><b>Online Streitbeilegung: </b> 
                <br />
                Verbraucher, welche in Österreich oder in einem sonstigen Vertragsstaat der ODR-VO niedergelassen sind,
                haben die Möglichkeit Probleme bezüglich dem entgeltlichen Kauf von Waren oder Dienstleistungen im Rahmen
                einer Online-Streitbeilegung (nach OS, AStG) zu lösen. Die Europäische Kommission stellt eine Plattform
                hierfür bereit: https://ec.europa.eu/consumers/odr
                </p>

                <p><b>Urheberrecht: </b>
                <br />
                Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich möglich ist, diversen Schutzrechten 
                (z.B dem Urheberrecht). Jegliche Verwendung/Verbreitung von bereitgestelltem Material, welche urheberrechtlich 
                untersagt ist, bedarf schriftlicher Zustimmung des Webseitenbetreibers.
                </p>
                <p><b>Haftungsausschluss: </b>
                <br />
                Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der Webseitenbetreiber dieser Webseite keine Haftung 
                für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber 
                verantwortlich. Sollten Sie dennoch auf ausgehende Links aufmerksam werden, welche auf eine Webseite mit 
                rechtswidriger Tätigkeit/Information verweisen, ersuchen wir um dementsprechenden Hinweis, um diese nach 
                § 17 Abs. 2 ECG umgehend zu entfernen.
                <br />
                Die Urheberrechte Dritter werden vom Betreiber dieser Webseite mit größter Sorgfalt beachtet. 
                Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. 
                Bei Bekanntwerden derartiger Rechtsverletzungen werden wir den betroffenen Inhalt umgehend entfernen.
                </p>
                <div className='quelle'>
                    <p>
                        <b>Quelle: </b>
                        <a href="https://www.ortner-rechtsanwalt.at/rechtsanwalt-fuer-arbeitsrecht/">
                            Rechtsanwalt Arbeitsrecht und Kündigung
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ImpressumText;
