import * as React from 'react';

import Header from '../components/header/Header';
import Home from '../components/home/Home';
import About from '../components/about/About';
import Footer from '../components/footer/Footer';

import '../components/home/Home.css';

function Homepage() {
    return (
        <div className="App">
            <Header />
            <Home />
            <About />
            <Footer />
        </div>
    );
}

export default Homepage;