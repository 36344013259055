import * as React from 'react';
import './AboutTextRight.css';


const AboutTextR: React.FC = () => {
    return (
        <div className='about-section'>
            <h1 className="about-title">My Professional Key Achievements</h1>
            <p className="about-text">
                <ul>
                    <li>
                        Successfully led data engineering projects at Epitome, 
                        focusing on robust, scalable, real-time data pipelines and software.
                    </li>
                    <li>
                        Implemented a data strategy for Epitome and data privacy policies ensuring compliance with GDPR.
                    </li>
                    <li>
                        Utilized computer vision for object detection and analysis in image data.
                    </li>
                </ul>
            </p>
            <h1 className="about-title">My Current and Future Endeavors</h1>
            <p className="about-text">
                My current role is as a System Architect with a focus on software development
                for the IT Service Management and IT Infrastructure department at the Bundesamt 
                für Eich- und Vermessungswesen (BEV). This position involves developing and 
                maintaining software architectures and solutions, rolling out new software versions, 
                and integrating them into existing systems.
            </p>

        </div>
    );
};

export default AboutTextR;