import * as React from 'react';
import './Home.css';
import image from './2024-05-25_LS_014.jpg';

const Home: React.FC = () => {
    return (
        <div className="home">
            <div className='home-container'>
                <div className='home-container-left'>
                    <div className="job-title">
                        <span className="job-title-text">Data Engineer | System Architect | Sociologist</span>
                    </div>
                    <div className="hello">
                        <span className="hello-text">Hi,</span>
                        <br />
                        <span className="hello-text">I'm Stefan Lacher.</span>
                    </div>
                </div>
                <div className='home-container-right'>
                    <img src={image} alt="" className='image-right' />
                </div>
            </div>

        </div>
    );
};

export default Home;