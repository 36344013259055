import * as React from 'react';
import './About.css';
import AboutText from './AboutTextLeft';
import AboutTextR from './AboutTextRight';
import AboutTextD from './AboutTextDown';

const About: React.FC = () => {
    return (
        <div>
            <div className="about grid-container" id="about">
                <AboutText />
                <AboutTextR />
            </div>
            <div className="about_down" id="contact_me">
                <AboutTextD />
            </div>
        </div>
    );
};

export default About;
