import * as React from 'react';
import './Header.css';
import headerLogo from './header-logo-removebg.png';


const Header: React.FC = () => {
    return (
        <header className="header">
            <div className="header-menu">
                <a href="/#about" className="menu-text">About</a>
                <a href="/" className="menu-text"><img src={headerLogo} alt="header-logo" className="header-logo" /></a>
                <a href="/#contact_me" className="menu-text">Contact me</a>
            </div>
        </header>
    );
};

export default Header;
